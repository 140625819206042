<div class="input-container sl-width-100 sl-v1">
	<div class="p-input-icon-right sl-width-100">
		<input 	#inputElement
				pInputText
				type="text"
				class="sl-v1 w-min-280"
				autocomplete="off"
				autocapitalize="false"
				autocorrect="off"
				[placeholder]="placeholderSignal$()"
				[value]="internalValue$()"
				[slAutoFocus]="autoFocusSignal$()"
				[pTooltip]="placeholderSignal$()"
				tooltipStyleClass="sl-v1-tooltip sl-v1-tooltip-no-wrap"
				[tooltipPosition]="tooltipPositionSignal$()"
				[disabled]="internalDisabled$()"
				(input)="onSearchChange($event)" />
		@if (!(internalValue$() || loadingSignal$())) {
			<mat-icon class="search-icon material-symbols-outlined">search</mat-icon>
		}
		@if (internalValue$()) {
			<i class="pi pi-times clickable" (click)="clearSearch(); inputElement.focus()"></i>
		}
		@if (loadingSignal$()) {
			<i class="pi pi-spinner"></i>
		}
	</div>
</div>
